.logo {

    display: flex;
    align-items: center;
    border-radius: 4px;

    img {
        object-fit: cover;
        border-radius: 4px;
        display: block;
    }
}
