$spaceamounts: (
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10
);
$sides: (
    top,
    bottom,
    left,
    right
);

@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}rem !important;
        }

        .m-#{$space} {
            margin: #{$space}rem !important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}rem !important;
        }

        .p-#{$space} {
            padding: #{$space}rem !important;
        }
    }
}