.object-fit {

    &-fill {
        object-fit: fill;
    }

    &-cover {
        object-fit: cover;
    }

    &-contain {
        object-fit: contain;
    }
}
