.app {
    height: 100vh;
    margin: 0 20%;
    background: var(--text-area-bg);
    position: relative;
    min-height: -webkit-fill-available;
}

.main {
    height: calc(100vh - 8.3rem);
}

@media (max-width:481px) {
    .app {
        margin: 0;
    }

    .main {
        height: calc(100vh - 7.4rem);
    }
}

@media (max-width:1024px) and (min-width: 482px) {
    .app {
        margin: 0 10%;
    }
}

@media (max-width:1480px) and (min-width: 1025px) {
    .app {
        margin: 0 16%;
    }
}