.cursor {
    &-pointer {
        cursor: pointer;
    }

    &-default {
        cursor: default;
    }

    &-text {
        cursor: text;
    }
}