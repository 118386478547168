html[data-theme="dark"] {
    ::-webkit-scrollbar-track {
        background: #333;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #555;
    }

    * {
        scrollbar-color: #555 #333;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #555;
    }

    *::-webkit-scrollbar-track {
        background: #333;
    }
}