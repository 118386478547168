.nav {
    padding: 1.1rem 1.4rem;
    border-bottom: 1px solid var(--border-color);
}

.update-note-link {
    label {
        opacity: 0.6;
    }
}


@media (max-width:481px) {
    .nav {
        padding: 1.1rem 1rem;

        .nav-btn {
            width: 28px;
            height: 28px;
            font-size: 12px;

            .ant-btn-icon {
                .anticon {
                    font-size: 14px;
                }
            }
        }
    }
}