.footer {
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 4rem;
    z-index: 999;
    background: var(--text-area-bg);

    ul {
        width: 100%;
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            float: left;
            font-size: 14px;
            opacity: 0.5;
            text-transform: capitalize;
            padding: 0px 10px;

            a {
                text-decoration: none;
            }
        }

        li:hover:not(:first-child) {
            opacity: 0.8;
        }

        li:not(:last-child) {
            border-right: 1px solid #bababa;
        }
    }
}

@media (max-width:481px) {
    .footer {
        width: 100%;
        height: 3.2rem;

        ul {
            li {
                font-size: 12px;
                padding: 0px 6px;
            }
        }
    }
}