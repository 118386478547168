.slate-editor {

    .ant-float-btn {
        position: absolute;
        top: 78px;
        height: 40px;
        right: 22px;

        @media (max-width:481px) {
            right: 16px;
            top: 73px;
        }
    }

    .quick-edit {
        position: absolute;
        top: 78px;
        height: 40px;
        right: 22px;
    }

    .text-editior-properties {
        overflow: hidden auto;
        outline: none;
        padding: 0.8rem 1.4rem;

        a {
            color: var(--link);
            opacity: 1;
        }

        a:hover {
            opacity: 0.9;
        }

        h1 {
            font-size: 1.601rem;

            @media (max-width:481px) {
                font-size: 1.365rem;
            }
        }

        h2 {
            font-size: 1.512rem;

            @media (max-width:481px) {
                font-size: 1.295rem;
            }
        }

        h3 {
            font-size: 1.425rem;

            @media (max-width:481px) {
                font-size: 1.225rem;
            }
        }

        h4 {
            font-size: 1.295rem;

            @media (max-width:481px) {
                font-size: 1.118rem;
            }
        }

        h5 {
            font-size: 1.224rem;

            @media (max-width:481px) {
                font-size: 1.085rem;
            }
        }

        h6 {
            font-size: 1.125rem;

            @media (max-width:481px) {
                font-size: 1.028rem;
            }
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        ol {
            margin: 2px 0px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            opacity: 0.9;
        }

        table,
        th,
        td {
            border: 1px solid var(--border-color);
            border-collapse: collapse;
        }

        th,
        td {
            padding: 10px;
        }

        @media (max-width:481px) {
            padding: 0.8rem 1rem;
        }
    }

    .text-editor {
        @extend .text-editior-properties;

        height: calc(100vh - 13.7rem);

        @media (max-width:481px) {
            height: calc(100vh - 12.4rem);
        }
    }

    .text-editor-readonly {
        @extend .text-editior-properties;

        height: calc(100vh - 9.9rem);

        @media (max-width:481px) {
            height: calc(100vh - 8.95rem);
        }
    }

    .code-block {
        letter-spacing: 2px;
        font-family: monaco, Consolas, Lucida Console, monospace;
        font-size: 14px;
        line-height: 18px;
        margin-top: -6px;
        background: var(--background-color);
        padding: 8px 14px;
        color: var(--text-color-light);
    }

    .checklist-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2px 0px;

        .checkbox {
            margin-right: 0.55rem;
        }

        .check-text {
            flex: 1;

            &:focus {
                outline: none;
            }
        }

        .check-text.checked {
            opacity: 0.666;
            text-decoration: line-through;
        }
    }

    .image-container {
        // max-width: 100%;
        max-height: 28em;
        border-radius: 4px;

        @media (max-width:481px) {
            max-height: 20em;
        }

        .image-element {
            display: block;
            max-width: 100%;
            max-height: 28em;
            border-radius: 4px;

            @media (max-width:481px) {
                max-height: 20em;
            }
        }
    }

    .image-element.selected {
        box-shadow: 0 0 0 2px var(--accent-color);
        ;
    }

    .toolbar {
        position: relative;
        padding: 1.2rem 1.4rem;
        border-bottom: 1px solid var(--border-color);
        animation: slide 0.5s forwards;
        top: -8px;

        @media (max-width:481px) {
            padding: 1rem;
        }

        @keyframes slide {
            100% {
                top: 0px;
            }
        }
    }
}

.editor-button {
    cursor: pointer;

    @media (max-width:481px) {
        font-size: 14px;
    }
}

.editor-menu {
    &>* {
        display: inline-block;
    }

    &>*+* {
        margin-left: 15px;

        @media (max-width:481px) {
            margin-left: 12px;
        }
    }
}

.hovering-toolbar {
    padding: 8px 7px 6px;
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    opacity: 0;
    background-color: #505050;
    border-radius: 4px;
    transition: opacity 0.75s;
}