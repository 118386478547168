$text-opacity-start: 1;
$text-opacity-end: 9;

.text {
    &-accent-color {
        color: var(--accent-color);
    }

    &-light {
        opacity: 0.6;
    }

    &-decoration {

        &-underline {
            text-decoration: underline;
        }

        &-none {
            text-decoration: none;
        }
    }

    &-transform {

        &-capitalize {
            text-transform: capitalize;
        }

        &-uppercase {
            text-transform: uppercase;
        }

        &-lowercase {
            text-transform: lowercase;
        }
    }

    &-align {
        &-center {
            text-align: center;
        }

        &-left {
            text-align: left;
        }

        &-right {
            text-align: right;
        }
    }

    &-opacity {
        @for $op from $text-opacity-start through $text-opacity-end {
            &-#{$op} {
                opacity: #{$op*0.1};
            }
        }
    }

}