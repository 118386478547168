@import "styles/index.scss";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Nunito", sans-serif;
}

:not(pre)>code {
  // background-color: var(--background-color);
  letter-spacing: 2px;
  font-family: consolas;
  font-family: monaco, Consolas, Lucida Console, monospace;
  font-size: 14px;

  span {
    opacity: 0.8;
  }
}

.site-form-item-icon {
  color: var(--icon-outline);
}

a {
  color: var(--text-color);
}

a:hover {
  color: var(--text-color);
  opacity: 0.9;
}

.ant-skeleton-content {

  ul,
  h3 {
    margin: 6px 0;
  }
}

.home {
  height: 100%;
}

.info-msg {
  .anticon {
    color: var(--accent-color) !important;
  }
}

.page-container {
  overflow: auto;
  height: 100%;

  .contain {
    padding: 1.4rem;

    @media (max-width:481px) {
      padding: 1rem;
    }
  }

}