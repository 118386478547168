.stack-header {

    padding: 1.1rem 1.4rem;
    border-bottom: 1px solid var(--border-color);

    .stack-name {
        font-size: 20px;
        opacity: 0.9;
        font-weight: 600;
    }

    .stack-description {
        opacity: 0.6;
        font-weight: 400;
        font-style: italic;
    }

    @media (max-width:481px) {
        padding: 0.7rem 1rem;

        .stack-name {
            font-size: 18px;
        }

        .stack-description {
            font-size: 14px;
        }
    }
}

.public-notes {

    .note-card {
        background-color: var(--background-color);
        position: relative;
        border-radius: 4px;
        transition: transform 0.3s ease;

        .note-header {
            padding: 0.7rem 1rem;
            cursor: pointer;

            .note-title {
                font-size: 17px;
                font-weight: 600;
                opacity: 0.9;
            }

            .note-description {
                opacity: 0.6;
                font-weight: 400;
                font-size: 14px;
            }
        }

        .note-footer {
            padding: 0 1rem;
            display: flex;
            align-items: center;
            height: 32px;
            border-top: 1px solid var(--border-color);

            .note-date {
                opacity: 0.6;
                font-size: 12px;
            }

            .note-link {
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 8px 0px 0px 8px;
                background-color: var(--text-area-bg);
                border-radius: 4px 0px 0px 0px;
            }
        }
    }

    .note-card:hover {
        transform: translate(0, -4px);

        .note-title {
            opacity: 1;
        }

        .note-description {
            opacity: 0.7;
        }
    }
}