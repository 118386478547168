.display {

    &-inline-block {
        display: inline-block;
    }

    &-block {
        display: block;
    }

    &-inline {
        display: inline;
    }

    &-flex {
        display: flex;
    }

    &-none {
        display: none;
    }

    &-table {
        display: table;
    }
}