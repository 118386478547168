$font-weight-start: 2;
$font-weight-end: 9;
$font-size-start: 9;
$font-size-end: 24;


.font {

    &-weight {
        @for $weight from $font-weight-start through $font-weight-end {
            &-#{$weight*100} {
                font-weight: #{$weight*100};
            }
        }
    }

    &-size {
        @for $size from $font-size-start through $font-size-end {
            &-#{$size} {
                font-size: #{$size}px;
            }
        }
    }
}