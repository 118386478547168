@use "sass:map";

html[data-theme="dark"] {
    --background-color: #{map.get($dark-theme, "background-color")};
    --text-area-bg: #{map.get($dark-theme, "text-area-bg")};
    --text-color: #{map.get($dark-theme, "text-color")};
    --text-color: #{map.get($dark-theme, "text-color-light")};
    --border-color: #{map.get($dark-theme, "border-color")};
    --icon-outline: rgba(255, 255, 255, 0.25);
    --link: #50a0ff;

}

html[data-theme="light"] {
    --background-color: #{map.get($light-theme, "background-color")};
    --text-area-bg: #{map.get($light-theme, "text-area-bg")};
    --text-color: #{map.get($light-theme, "text-color")};
    --text-color: #{map.get($light-theme, "text-color-light")};
    --border-color: #{map.get($light-theme, "border-color")};
    --icon-outline: rgba(0, 0, 0, 0.25);
    --link: #1c5fff;
}

html {
    --accent-color: #{$accent-color};
}