.title {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
    color: var(--accent-color);
    font-family: "Madimi One", sans-serif;
    line-height: 30px;
    margin: 0px;
}

@media (max-width:481px) {
    .title {
        font-size: 17px;
    }
}